
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinSubmit from "@/mixins/mixinSubmit";
  import dialog from "@/vuex/dialog";
  import Shift from "@/typesold/shift";
  import manager from "@/vuex/submit/manager";
  import CommonProgress from "@/components/common/Progress.vue";
  import SubmitSubmitcontrol from "@/components/submit/Submitcontrol.vue";
  import SubmitApplybutton from "@/components/submit/Applybutton.vue";
  import SubmitSubmittable from "@/components/submit/Submittable.vue";
  import CommonLongbutton from "@/components/common/Longbutton.vue";

  @Component({
    components: {
      CommonProgress,
      SubmitSubmitcontrol,
      SubmitApplybutton,
      SubmitSubmittable,
      CommonLongbutton,
    },
  })
  export default class Main extends Mixins(mixinSubmit) {
    update_ables() {
      manager.updateAbles({
        yearmonth: this.date,
        branch_id: this.viewing_branch,
        employee_id: this.s_employee_id,
      });
    }
    update_shifts() {
      manager.updateShifts({
        yearmonth: this.date,
        branch_id: this.viewing_branch,
        employee_id: this.s_employee_id,
        coretime_type: this.global.coretime_type,
        coretime_started_at: this.global.coretime_started_at,
        coretime_ended_at: this.global.coretime_ended_at,
      });
    }
  }
