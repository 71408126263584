
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinSubmit from "@/mixins/mixinSubmit";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/submit/manager";
  import Able from "@/typesold/able";
  import Able_pattern from "@/typesold/able_pattern";

  @Component({
    components: {},
  })
  export default class Inputrow extends Mixins(mixinSubmit) {
    @Prop() dateprop: string;
    @Prop() dayprop: number;
    @Prop() is_holiday: boolean;

    get datestr() {
      const dateobj = util.date2obj(this.dateprop);
      if (this.is_sp) {
        return util.formatDate(dateobj, "mm/dd");
      } else {
        return this.filter.monthday(this.dateprop);
      }
    }
    get daystr() {
      return this.filter.week(this.dateprop);
    }

    get able() {
      return manager.ables[this.dateprop];
    }

    get max_row(): number {
      const able = this.able.able_time.filter((time) => {
        return time.started_at !== null && time.ended_at !== null;
      });

      return able.length;
    }
  }
