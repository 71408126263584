
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinSubmit from "@/mixins/mixinSubmit";
  import monthly from "@/vuex/submit/monthly";
  import daily from "@/vuex/submit/daily";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/submit/manager";
  import Able from "@/typesold/able";
  import Able_pattern from "@/typesold/able_pattern";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonTime from "@/components/common/Time.vue";

  @Component({
    components: {
      CommonSelect,
      CommonTime,
    },
  })
  export default class Main extends Mixins(mixinSubmit) {
    get able_pattern_value() {
      return null;
    }
    set able_pattern_value(val) {
      if (val == 0) {
        this.shift = {
          id: null,
          employee_id: null,
          date: null,
          shift_shift: [
            {
              id: null,
              shift_id: null,
              started_at: null,
              ended_at: null,
            },
          ],
          shift_break: [
            {
              id: null,
              shift_id: null,
              started_at: null,
              ended_at: null,
            },
          ],
          shift_punch: this.shift.shift_punch,
        };
      } else {
        const shift = this.convert_pattern_shift(
          this.global.able_patterns.find((able_pattern) => able_pattern.id == val),
        );
        this.shift = {
          id: null,
          employee_id: null,
          date: null,
          shift_shift: shift.shift_shift,
          shift_break: shift.shift_break,
          shift_punch: this.shift.shift_punch,
        };
      }
    }

    convert_pattern_shift(able_pattern: Able_pattern) {
      const shift: Shift = {
        id: null,
        employee_id: null,
        date: this.calendar_date,
        shift_shift: able_pattern.able_pattern_time.map((time) => {
          return {
            id: 0,
            shift_id: 0,
            started_at: time.started_at,
            ended_at: time.ended_at,
          };
        }),
        shift_break: [
          {
            id: null,
            shift_id: null,
            started_at: null,
            ended_at: null,
          },
        ],
        shift_punch: [],
      };
      return shift;
    }

    get datestr() {
      const dateobj = util.date2obj(this.calendar_date);
      if (this.is_sp) {
        return util.formatDate(dateobj, "mm/dd");
      } else {
        return util.formatDate(dateobj, "mm月dd日");
      }
    }
    get daystr() {
      return util.day2weekstr(this.dayprop);
    }

    get shift() {
      return this.m.tmp_shift;
    }
    set shift(val) {
      this.m.setTmpShift({ shift: val });
    }

    get max_shift_row(): number {
      if (this.shift) {
        this.shift_input();
      }

      return this.shift.shift_shift.length;
    }

    get max_break_row(): number {
      if (this.shift) {
        this.break_input();
      }

      return this.shift.shift_break.length;
    }

    get able_pattern_option() {
      return this.global.able_pattern_option;
    }

    shift_input() {
      this.shift.shift_shift = this.shift_shift_input(this.shift.shift_shift);
    }
    break_input() {
      this.shift.shift_break = this.shift_break_input(this.shift.shift_break);
    }

    shift_shift_input(shift_shifts) {
      const length = shift_shifts.length - 1;
      shift_shifts = shift_shifts.filter(
        (timeint, j) => timeint.started_at != null || timeint.ended_at != null || length == j,
      );
      if (shift_shifts.length == 0) {
        shift_shifts.push({ id: null, shift_id: null, started_at: null, ended_at: null });
      } else {
        const shift_shift = shift_shifts[shift_shifts.length - 1];
        if (shift_shift.started_at != null || shift_shift.ended_at != null) {
          shift_shifts.push({ id: null, shift_id: null, started_at: null, ended_at: null });
        }
      }
      return shift_shifts;
    }
    shift_break_input(shift_breaks) {
      const length = shift_breaks.length - 1;
      shift_breaks = shift_breaks.filter(
        (timeint, j) => timeint.started_at != null || timeint.ended_at != null || length == j,
      );
      if (shift_breaks.length == 0) {
        shift_breaks.push({ id: null, shift_id: null, started_at: null, ended_at: null });
      } else {
        const shift_break = shift_breaks[shift_breaks.length - 1];
        if (shift_break.started_at != null || shift_break.ended_at != null) {
          shift_breaks.push({ id: null, shift_id: null, started_at: null, ended_at: null });
        }
      }
      return shift_breaks;
    }
  }
