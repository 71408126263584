
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinSubmit from '@/mixins/mixinSubmit';
import dialog from "@/vuex/dialog";
import global from "@/vuex/submit/global";
import manager from "@/vuex/submit/manager";
import header from "@/vuex/header";
import ScrollHint from 'scroll-hint';
import SubmitInputshiftrow from "@/components/submit/Inputshiftrow.vue";
import SubmitInputablerow from "@/components/submit/Inputablerow.vue";

@Component({
  components: {
    SubmitInputshiftrow,
    SubmitInputablerow,
  }
})
export default class Shiftbundletable extends Mixins(mixinSubmit) {
  mounted() {
    this.enableScrollHint();
  }
  

  get date_array_with_holiday() {
    return util.getMonthDateArrayNationalHoliday(this.header.national_holidays, this.date)
  }
}
