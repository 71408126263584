
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinSubmit from '@/mixins/mixinSubmit';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/submit/manager";
import CommonLongbutton from "@/components/common/Longbutton.vue";

@Component({
  components: {
    CommonLongbutton,
  }
})
export default class Applybutton extends Mixins(mixinSubmit) {
  apply_shiftbundle_control() {
    if (this.is_flextime) {
      manager.applyShiftControl({yearmonth: this.date, national_holidays: this.header.national_holidays});
    } else {
      manager.applyAbleControl({yearmonth: this.date, national_holidays: this.header.national_holidays});
    }
  }
}
