import { Component, Vue, Mixins } from 'vue-property-decorator';
import global from "@/vuex/submit/global";
import * as util from '@/util';
import utilMixins from '@/mixins';
import manager from '@/vuex/submit/manager';
import monthly from "@/vuex/submit/monthly";

@Component
export default class mixinSubmit extends Mixins(utilMixins) {
  get is_set_global(): boolean {
    return global.is_set;
  }
  get is_set_monthly(): boolean {
    return monthly.data[this.date] && monthly.data[this.date][this.viewing_branch] && monthly.data[this.date][this.viewing_branch].is_set;
  }

  get is_valid_global(): boolean {
    return global.is_valid;
  }
  get is_valid_monthly(): boolean {
    return monthly.data[this.date] && monthly.data[this.date][this.viewing_branch] && monthly.data[this.date][this.viewing_branch].is_valid;
  }

  async get_global() {
    await global.fetch();
  }
  async get_monthly() {
    await monthly.fetch({yearmonth: this.date, branch_id: this.viewing_branch});
  }

  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) 
      return global.data;
  }
  get monthly() {
    if (!this.is_set_monthly || !this.is_valid_monthly) this.get_monthly();
    if (this.is_set_monthly)
      return monthly.data[this.date][this.viewing_branch];
  }
  
  get m() {
    return manager;
  }

  get todayobj() {
    return new Date();
  }


  get ables() {
    return this.monthly.ables;
  }
  get shifts() {
    return this.monthly.shifts;
  }
  get needs() {
    return this.monthly.needs;
  }



  get viewing_branch() {
    var branch_id = this.query.viewing_branch;
    if (branch_id === undefined) {
      branch_id = Number(util.getLocalStorage('branch_id'));
      this.query = {viewing_branch: branch_id};
    }
    return branch_id;
  }
  set viewing_branch(val) {
    this.query = {viewing_branch: val};
  }
  
  get dateobj() {
    return util.date2obj(this.date + '-01');
  }
  get calendardateobj() {
    return util.date2obj(this.calendar_date);
  }
  get date() {
    var date = this.query.submit_date;
    if (!date) {
      const date_obj = new Date();
      date_obj.setMonth(date_obj.getMonth() + 1);
      date = util.formatDate(date_obj, 'yyyy-mm');
      this.query = {submit_date: date};
    }
    return date;
  }
  set date(val) {
    if (val != this.date) {
      this.query_push = {submit_date: val};
    }
  }
  get calendar_date() {
    var date = this.query.submit_calendar_date;
    if (!date) {
      const date_obj = new Date();
      date_obj.setMonth(date_obj.getMonth() + 1);
      date = util.formatDate(date_obj, 'yyyy-mm-dd');
      this.query = {submit_calendar_date: date};
    }
    return date;
  }
  set calendar_date(val) {
    if (val != this.date) {
      this.query = {submit_calendar_date: val};
    }
  }

  get mode() {
    if (!this.is_sp) {
      return 0;
    }
    return this.m.mode;
  }
  set mode(val) {
    util.setLocalStorage('submit_mode', val);
    manager.setMode(val);
  }
}