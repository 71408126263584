
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinSubmit from "@/mixins/mixinSubmit";
  import dialog from "@/vuex/dialog";
  import Able_pattern from "@/typesold/able_pattern";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonButton,
    },
  })
  export default class Normalrow extends Mixins(mixinSubmit) {
    @Prop() able_pattern: Able_pattern;

    get max_row(): number {
      return Math.max(
        this.able_pattern.able_pattern_time.length,
        this.able_pattern.able_pattern_break.length,
      );
    }

    delete_able_pattern(able_pattern_id) {
      this.m.deleteAblePattern({ able_pattern_id });
    }
  }
