
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinSubmit from '@/mixins/mixinSubmit';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/shift/manager";
import shiftbundle_global from "@/vuex/shift/shiftbundle_global";
import shiftbundle_manager from "@/vuex/shift/shiftbundle_manager";
import SubmitBranchselector from "@/components/submit/Branchselector.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    SubmitBranchselector,
    CommonButton,
  }
})
export default class Controlbutton extends Mixins(mixinSubmit) {
}
