
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinSubmit from '@/mixins/mixinSubmit';
import dialog from "@/vuex/dialog";
import monthly from "@/vuex/shift/monthly";
import manager from "@/vuex/shift/manager";

@Component({
  components: {
  }
})
export default class Main extends Mixins(mixinSubmit) {

  is_same_date(date1, date2) {
    if (date1.getFullYear() == date2.getFullYear()) {
      if (date1.getMonth() == date2.getMonth()) {
        if (date1.getDate() == date2.getDate()) {
          return true;
        }
      }
    }
    return false;
  }
  is_same_month(date1, date2) {
    if (date1.getFullYear() == date2.getFullYear()) {
      if (date1.getMonth() == date2.getMonth()) {
        return true;
      }
    }
    return false;
  }


  get calendar_data() {
    let dateobj = this.dateobj;
    var startdateobj = new Date(this.dateobj);
    startdateobj.setDate(1);

    //初めの週を日曜まで戻す
    for(var i = startdateobj.getDay(); i > 0; i--) {
      startdateobj.setDate(startdateobj.getDate() - 1);
    }

    var calendar_data = [];

    for (; ;) {
      const calendar_week = [];
      for (var j = 0; j < 7; j++) {
        calendar_week.push({date: new Date(startdateobj), need: this.needs[util.formatDate(startdateobj, 'yyyy-mm-dd')]});
        startdateobj.setDate(startdateobj.getDate() + 1);
      }
      calendar_data.push(calendar_week);
      if (startdateobj.getMonth() != dateobj.getMonth()) {
        break;
      }
    }
    
    return calendar_data;
  }

  setDate(date: Date) {
    this.calendar_date = util.formatDate(date, 'yyyy-mm-dd');
    if (this.is_flextime) {
      this.m.setTmpShift({shift: util.deep_copy(this.m.shifts[this.calendar_date])});
    } else {
      this.m.setTmpAble({able: util.deep_copy(this.m.ables[this.calendar_date])});
    }
    this.m.setIsOpenSubmitDialog(true);
  }

  is_input(date) {
    var calendar_date = util.formatDate(date, 'yyyy-mm-dd');
    if (this.is_flextime) {
      if (this.m.shifts[calendar_date]) {
        var shift_shift = this.m.shifts[calendar_date].shift_shift.filter(time => {
          return time.started_at !== null && time.ended_at !== null;
        });
        var shift_break = this.m.shifts[calendar_date].shift_break.filter(time => {
          return time.started_at !== null && time.ended_at !== null;
        });
        return shift_shift.length != 0 || shift_break.length != 0;
      }
    } else {
      if (this.m.ables[calendar_date]) {
        var able_time = this.m.ables[calendar_date].able_time.filter(time => {
          return time.started_at !== null && time.ended_at !== null;
        });
        return able_time.length != 0;
      }
    }
    return false;
  }
}
