
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinSubmit from '@/mixins/mixinSubmit';
import monthly from "@/vuex/submit/monthly";
import daily from "@/vuex/submit/daily";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/submit/manager";
import SubmitCalendarSubmitShift from "@/components/submit/calendar/submit/Shift.vue";
import SubmitCalendarSubmitAble from "@/components/submit/calendar/submit/Able.vue";

@Component({
  components: {
    SubmitCalendarSubmitShift,
    SubmitCalendarSubmitAble,
  }
})
export default class Main extends Mixins(mixinSubmit) {
  get submits() {
    return this.daily.submits;
  }
  get managed_submits() {
    var submits = util.deep_copy(this.submits);
    submits = this.sort_submit(submits);
    return submits;
  }
  get ables() {
    return this.daily.ables;
  }
  get employee_hash() {
    return this.daily.employee_hash;
  }
  get submit_memo() {
    if (this.daily) {
      if (this.daily.submit_memo) {
        return this.daily.submit_memo.content;
      } else {
        return '';
      }
    }
  }
}
