
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinSubmit from "@/mixins/mixinSubmit";
  import global from "@/vuex/submit/global";
  import dialog from "@/vuex/dialog";
  import Able_pattern from "@/typesold/able_pattern";
  import CommonText from "@/components/common/Text.vue";
  import CommonTime from "@/components/common/Time.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonText,
      CommonTime,
      CommonButton,
    },
  })
  export default class Inputrow extends Mixins(mixinSubmit) {
    @Prop() editable_pattern: Able_pattern;

    able_pattern: Able_pattern = {
      id: null,
      name: null,
      employee_id: null,
      able_pattern_time: [],
      able_pattern_break: [],
    };

    created() {
      if (this.editable_pattern) {
        this.able_pattern = util.deep_copy(this.editable_pattern);
      } else {
        this.able_pattern = {
          id: null,
          name: null,
          employee_id: null,
          able_pattern_time: [],
          able_pattern_break: [],
        };
      }
      this.able_pattern_time_input();
      this.able_pattern_break_input();
    }

    create_able_pattern() {
      this.m.createAblePattern({ able_pattern: this.able_pattern });
    }
    update_able_pattern() {
      this.m.updateAblePattern({
        able_pattern_id: this.m.edit_able_pattern_flag,
        able_pattern: this.able_pattern,
      });
    }

    get is_edit() {
      return this.editable_pattern;
    }
    get is_add() {
      return !this.editable_pattern;
    }

    get max_row(): number {
      return Math.max(
        this.able_pattern.able_pattern_time.length,
        this.able_pattern.able_pattern_break.length,
      );
    }

    able_pattern_time_input() {
      this.able_pattern.able_pattern_time = this.time_input(this.able_pattern.able_pattern_time);
    }
    able_pattern_break_input() {
      this.able_pattern.able_pattern_break = this.time_input(this.able_pattern.able_pattern_break);
    }

    time_input(able_pattern_times) {
      const length = able_pattern_times.length - 1;
      able_pattern_times = able_pattern_times.filter(
        (timeint, j) => timeint.started_at != null || timeint.ended_at != null || length == j,
      );
      if (able_pattern_times.length == 0) {
        able_pattern_times.push({
          id: null,
          able_pattern_id: null,
          started_at: null,
          ended_at: null,
        });
      } else {
        const able_pattern_time = able_pattern_times[able_pattern_times.length - 1];
        if (able_pattern_time.started_at != null || able_pattern_time.ended_at != null) {
          able_pattern_times.push({
            id: null,
            able_pattern_id: null,
            started_at: null,
            ended_at: null,
          });
        }
      }
      return able_pattern_times;
    }
  }
