
  import { Component, Mixins } from "vue-property-decorator";
  import mixinSubmit from "@/mixins/mixinSubmit";
  import Able from "@/typesold/able";
  import Able_pattern from "@/typesold/able_pattern";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonTime from "@/components/common/Time.vue";
  import CommonTextarea from "@/components/common/Textarea.vue";

  @Component({
    components: {
      CommonSelect,
      CommonTime,
      CommonTextarea,
    },
  })
  export default class Main extends Mixins(mixinSubmit) {
    get able() {
      return this.m.tmp_able;
    }
    set able(val) {
      this.m.setTmpAble({ able: val });
    }

    get max_row(): number {
      if (this.able) {
        this.able_input();
      }
      if (this.need) {
        return Math.max(this.able.able_time.length, this.need.need_time.length);
      } else {
        return this.able.able_time.length;
      }
    }

    get need() {
      if (this.needs[this.calendar_date]) {
        return this.needs[this.calendar_date];
      }
    }

    get able_pattern_option() {
      return this.global.able_pattern_option;
    }

    able_input_each(type, i, val) {
      if (this.able.able_time[i - 1]) {
        const able = this.able.able_time[i - 1];
        able[type] = val;
        this.able.able_time[i - 1] = able;
        this.able.able_time = this.time_input(this.able.able_time);
      }
    }

    able_input() {
      this.able.able_time = this.time_input(this.able.able_time);
    }

    time_input(able_times) {
      const length = able_times.length - 1;
      able_times = able_times.filter(
        (timeint, j) => timeint.started_at != null || timeint.ended_at != null || length == j,
      );
      if (able_times.length == 0) {
        able_times.push({ id: null, able_id: null, started_at: null, ended_at: null });
      } else {
        const able_time = able_times[able_times.length - 1];
        if (able_time.started_at != null || able_time.ended_at != null) {
          able_times.push({ id: null, able_id: null, started_at: null, ended_at: null });
        }
      }
      return able_times;
    }

    get able_pattern_value() {
      return null;
    }
    set able_pattern_value(val) {
      if (val == 0) {
        this.able = {
          id: null,
          employee_id: null,
          date: null,
          content: this.able.content,
          able_time: [
            {
              id: null,
              able_id: null,
              started_at: null,
              ended_at: null,
            },
          ],
        };
      } else {
        this.able = this.convert_pattern_able(
          this.global.able_patterns.find((able_pattern) => able_pattern.id == val),
        );
      }
    }

    convert_pattern_able(able_pattern: Able_pattern) {
      const able: Able = {
        id: null,
        employee_id: null,
        date: this.dateprop,
        content: null,
        able_time: able_pattern.able_pattern_time.map((time) => {
          return {
            id: 0,
            able_id: 0,
            started_at: time.started_at,
            ended_at: time.ended_at,
          };
        }),
      };
      return able;
    }
  }
