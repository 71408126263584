
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinSubmit from '@/mixins/mixinSubmit';
import global from "@/vuex/shift/global";
import dialog from "@/vuex/dialog";
import CommonPager from "@/components/common/Pager.vue";

@Component({
  components: {
    CommonPager,
  }
})
export default class Monthchanger extends Mixins(mixinSubmit) {
  //apiの問い合わせが終わっていて、modeにかかわらない場所として選択
  created() {
    this.m.getAbleShift({yearmonth: this.date, branch_id: this.viewing_branch});

    let mode = Number(util.getLocalStorage('submit_mode'));
    if (mode === null) {
      mode = 0;
    }
    this.m.setMode(mode);
  }


  @Watch('viewing_branch')
  @Watch('date')
  on_change_ables(newval) {
    this.m.getAbleShift({yearmonth: this.date, branch_id: this.viewing_branch});
  }

  get yearmonthstr() {
    return this.filter.yearmonth(this.date);
  }

  prev_month() {
    var dateobj = new Date(this.dateobj);
    dateobj.setMonth(dateobj.getMonth() - 1);
    this.date = util.formatDate(dateobj, 'yyyy-mm');
  }
  next_month() {
    var dateobj = new Date(this.dateobj);
    dateobj.setMonth(dateobj.getMonth() + 1);
    this.date = util.formatDate(dateobj, 'yyyy-mm');
  }
}
