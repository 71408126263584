
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinSubmit from '@/mixins/mixinSubmit';
import dialog from "@/vuex/dialog";
import global from "@/vuex/submit/global";
import manager from "@/vuex/submit/manager";
import UtilPopup from "@/components/util/Popup.vue";
import SubmitAblepatternControlbutton from "@/components/submit/ablepattern/Controlbutton.vue";
import SubmitAblepatternInputrow from "@/components/submit/ablepattern/Inputrow.vue";
import SubmitAblepatternNormalrow from "@/components/submit/ablepattern/Normalrow.vue";
import SubmitAblepatternNoablepatternrow from "@/components/submit/ablepattern/Noablepatternrow.vue";

@Component({
  components: {
    UtilPopup,
    SubmitAblepatternControlbutton,
    SubmitAblepatternInputrow,
    SubmitAblepatternNormalrow,
    SubmitAblepatternNoablepatternrow,
  }
})
export default class Main extends Mixins(mixinSubmit) {
  get able_patterns() {
    return this.global.able_patterns;
  }

  get is_display() {
    return this.m.is_open_able_pattern_dialog;
  }
  set is_display(val: boolean) {
    this.m.cancelAll();
    this.m.setIsOpenAblePatternDialog(val);
  }

}
