
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinSubmit from "@/mixins/mixinSubmit";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager";
  import global from "@/vuex/submit/global";
  import submit_manager from "@/vuex/submit/manager";
  import Able from "@/typesold/able";
  import Able_pattern from "@/typesold/able_pattern";
  import CommonTime from "@/components/common/Time.vue";
  import CommonCheck from "@/components/common/Check.vue";
  import CommonSelect from "@/components/common/Select.vue";

  @Component({
    components: {
      CommonTime,
      CommonCheck,
      CommonSelect,
    },
  })
  export default class Submitcontrol extends Mixins(mixinSubmit) {
    get able_pattern_value() {
      return null;
    }
    set able_pattern_value(val) {
      if (val == 0) {
        this.able = {
          id: null,
          employee_id: null,
          date: null,
          able_time: [
            {
              id: null,
              able_id: null,
              started_at: null,
              ended_at: null,
            },
          ],
          able_break_time: [
            {
              id: null,
              able_id: null,
              started_at: null,
              ended_at: null,
            },
          ],
          content: null,
        };
      } else {
        this.able = this.convert_pattern_able(
          this.global.able_patterns.find((able_pattern) => able_pattern.id == val),
        );
        this.able_input();
        this.able_break_input();
      }
    }

    get max_row() {
      return Math.max(this.able.able_time.length, this.able.able_break_time.length);
    }

    convert_pattern_able(able_pattern: Able_pattern) {
      const able: Able = {
        id: null,
        employee_id: null,
        date: null,
        able_time: able_pattern.able_pattern_time.map((time) => {
          return {
            id: 0,
            able_id: 0,
            started_at: time.started_at,
            ended_at: time.ended_at,
          };
        }),
        able_break_time: able_pattern.able_pattern_break.map((time) => {
          return {
            id: 0,
            able_id: 0,
            started_at: time.started_at,
            ended_at: time.ended_at,
          };
        }),
        content: null,
      };
      return able;
    }

    get able() {
      return submit_manager.able_control;
    }
    set able(able: Able) {
      submit_manager.setAbleControl({ able });
    }

    get days() {
      return submit_manager.days_control;
    }
    set days(days: number[]) {
      submit_manager.setDaysControl({ days });
    }

    get able_pattern_option() {
      return this.global.able_pattern_option;
    }

    able_input() {
      this.able.able_time = this.time_input(this.able.able_time);
    }
    able_break_input() {
      this.able.able_break_time = this.time_input(this.able.able_break_time);
    }

    time_input(able_times) {
      const length = able_times.length - 1;
      able_times = able_times.filter(
        (timeint, j) => timeint.started_at != null || timeint.ended_at != null || length == j,
      );
      if (able_times.length == 0) {
        able_times.push({ id: null, able_id: null, started_at: null, ended_at: null });
      } else {
        const able_time = able_times[able_times.length - 1];
        if (able_time.started_at != null || able_time.ended_at != null) {
          able_times.push({ id: null, able_id: null, started_at: null, ended_at: null });
        }
      }
      return able_times;
    }
  }
