
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinSubmit from "@/mixins/mixinSubmit";
  import dialog from "@/vuex/dialog";
  import Shift from "@/typesold/shift";
  import manager from "@/vuex/submit/manager";
  import CommonProgress from "@/components/common/Progress.vue";
  import SubmitMonthchanger from "@/components/submit/Monthchanger.vue";
  import SubmitControlbutton from "@/components/submit/Controlbutton.vue";
  import SubmitCoretime from "@/components/submit/Coretime.vue";
  import CommonToggle from "@/components/common/Toggle.vue";
  import SubmitCalendarMain from "@/components/submit/calendar/Main.vue";
  import SubmitNormalMain from "@/components/submit/normal/Main.vue";

  @Component({
    components: {
      CommonProgress,
      SubmitMonthchanger,
      SubmitControlbutton,
      SubmitCoretime,
      CommonToggle,
      SubmitCalendarMain,
      SubmitNormalMain,
    },
  })
  export default class Main extends Mixins(mixinSubmit) {
    get mode_value() {
      return this.mode;
    }
    set mode_value(val) {
      if (val) {
        this.mode = 1;
      } else {
        this.mode = 0;
      }
    }

    update_ables() {
      manager.updateAbles({
        yearmonth: this.date,
        branch_id: this.viewing_branch,
        employee_id: this.s_employee_id,
      });
    }
    update_shifts() {
      manager.updateShifts({
        yearmonth: this.date,
        branch_id: this.viewing_branch,
        employee_id: this.s_employee_id,
        coretime_type: this.global.coretime_type,
        coretime_started_at: this.global.coretime_started_at,
        coretime_ended_at: this.global.coretime_ended_at,
      });
    }
  }
