
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinSubmit from '@/mixins/mixinSubmit';
import monthly from "@/vuex/submit/monthly";
import daily from "@/vuex/submit/daily";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/submit/manager";
import SubmitCalendarSubmitDatechanger from "@/components/submit/calendar/submit/Datechanger.vue";
import SubmitCalendarSubmitSubmit from "@/components/submit/calendar/submit/Submit.vue";

@Component({
  components: {
    SubmitCalendarSubmitDatechanger,
    SubmitCalendarSubmitSubmit,
  }
})
export default class Main extends Mixins(mixinSubmit) {
}
