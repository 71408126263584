
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinSubmit from '@/mixins/mixinSubmit';
import dialog from "@/vuex/dialog";
import global from "@/vuex/submit/global";
import manager from "@/vuex/submit/manager";
import header from "@/vuex/header";
import SubmitCalendarShiftrow from "@/components/submit/calendar/Shiftrow.vue";
import SubmitCalendarAblerow from "@/components/submit/calendar/Ablerow.vue";

@Component({
  components: {
    SubmitCalendarShiftrow,
    SubmitCalendarAblerow,
  }
})
export default class Shiftbundletable extends Mixins(mixinSubmit) {
  

  

  get date_array_with_holiday() {
    return util.getMonthDateArrayNationalHoliday(this.header.national_holidays, this.date)
  }
}
