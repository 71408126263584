
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinSubmit from "@/mixins/mixinSubmit";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/submit/manager";
  import Shift from "@/typesold/shift";
  import Able from "@/typesold/able";
  import Able_pattern from "@/typesold/able_pattern";

  @Component({
    components: {},
  })
  export default class Inputrow extends Mixins(mixinSubmit) {
    @Prop() dateprop: string;
    @Prop() dayprop: number;
    @Prop() is_holiday: boolean;

    get need() {
      if (this.needs[this.dateprop]) {
        return this.needs[this.dateprop];
      }
    }

    get datestr() {
      const dateobj = util.date2obj(this.dateprop);
      if (this.is_sp) {
        return util.formatDate(dateobj, "mm/dd");
      } else {
        return this.filter.monthday(this.dateprop);
      }
    }
    get daystr() {
      return this.filter.week(this.dateprop);
    }

    get shift() {
      return manager.shifts[this.dateprop];
    }

    get max_row(): number {
      const shift_shift = this.shift.shift_shift.filter((time) => {
        return time.started_at !== null && time.ended_at !== null;
      });
      const shift_break = this.shift.shift_break.filter((time) => {
        return time.started_at !== null && time.ended_at !== null;
      });
      return Math.max(shift_shift.length, shift_break.length);
    }
  }
