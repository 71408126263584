
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import mixinSubmit from '@/mixins/mixinSubmit';
import * as util from '@/util';
import UtilPopup from "@/components/util/Popup.vue";
import SubmitCalendarSubmitMain from "@/components/submit/calendar/submit/Main.vue";

@Component({
  components: {
    UtilPopup,
    SubmitCalendarSubmitMain,
  }
})
export default class Dialog extends Mixins(mixinSubmit) {
  get is_display() {
    return this.m.is_open_submit_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenSubmitDialog(val);
  }

  save() {
    if (this.is_flextime) {
      this.m.setShift({date: this.calendar_date, shift: this.m.tmp_shift});
    } else {
      this.m.setAble({date: this.calendar_date, able: this.m.tmp_able});
    }
    this.is_display = false;
  }

  close() {
    if (this.is_flextime) {
      this.close_shift();
    } else {
      this.close_able();
    }
  }
  close_shift() {
    var raw_shift = {
      shift_shift: this.m.shifts[this.calendar_date]?.shift_shift.filter(shift => shift.started_at !== null || shift.ended_at !== null),
      shift_break: this.m.shifts[this.calendar_date]?.shift_break.filter(shift => shift.started_at !== null || shift.ended_at !== null),
    }
    var tmp_shift = util.deep_copy(this.m.tmp_shift);
    var shift = {
      shift_shift: tmp_shift?.shift_shift.filter(shift => shift.started_at !== null || shift.ended_at !== null),
      shift_break: tmp_shift?.shift_break.filter(shift => shift.started_at !== null || shift.ended_at !== null),
    }
    if (JSON.stringify(raw_shift) != JSON.stringify(shift)) {
      dialog.openConfirmDialog({msg: '閉じてよろしいですか？', detail: '入力した内容は保存されていません。', exec_button: '破棄して閉じる', func: this.close_dialog, args: {self: this}});
    } else {
      this.is_display = false;
    }
  }
  close_able() {
    var raw_able = {
      able_time: this.m.ables[this.calendar_date]?.able_time.filter(time => time.started_at !== null || time.ended_at !== null),
      content: this.m.ables[this.calendar_date]?.content,
    }
    var tmp_able = util.deep_copy(this.m.tmp_able);
    var able = {
      able_time: tmp_able?.able_time.filter(time => time.started_at !== null || time.ended_at !== null),
      content: tmp_able?.content,
    }
    if (JSON.stringify(raw_able) != JSON.stringify(able)) {
      dialog.openConfirmDialog({msg: '閉じてよろしいですか？', detail: '入力した内容は保存されていません。', exec_button: '破棄して閉じる', func: this.close_dialog, args: {self: this}});
    } else {
      this.is_display = false;
    }
  }
  close_dialog({self}) {
    self.is_display = false;
  }
}
