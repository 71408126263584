
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import SubmitMain from "@/components/submit/Main.vue";
import SubmitAblepatternMain from "@/components/submit/ablepattern/Main.vue";

@Component({
  components: {
    SubmitMain,
    SubmitAblepatternMain,
  }
})
export default class Submit extends Mixins(utilMixins) {
}
