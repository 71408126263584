
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinSubmit from '@/mixins/mixinSubmit';
import dialog from "@/vuex/dialog";
import global from "@/vuex/submit/global";
import manager from "@/vuex/submit/manager";
import header from "@/vuex/header";

@Component({
  components: {
  }
})
export default class Shiftbundletable extends Mixins(mixinSubmit) {

}
